import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import TikTokVerifiedLogo from './icons/TikTokVerifiedLogo';

function Header() {
  const { allPrismic } = useStaticQuery(graphql`
    query SiteTitleQuery {
      allPrismic {
        nodes {
          data {
            username
            profile_picture {
              url
            }
          }
        }
      }
    }
  `);

  const { username, profile_picture: profilePicture } = allPrismic.nodes[0].data;

  return (
    <header className="sticky top-0 p-6 text-center bg-white shadow-md" style={{ zIndex: 1000 }}>
      <div className="max-w-xs mx-auto">
        <img src={profilePicture.url} alt="avatar" className="block w-12 mx-auto rounded-full" />
        <h1 className="mt-2 text-xl font-semibold tracking-wide text-gray-800">
          <span>
            {username}
            <TikTokVerifiedLogo className="inline-block ml-1" />
          </span>
        </h1>
      </div>
    </header>
  );
}

export default Header;
