import PropTypes from 'prop-types';
import React from 'react';
import unicorn from '../images/unicorn.svg';

import Header from './header';

function Layout({ children }) {
  return (
    <div
      className="flex flex-col min-h-screen font-sans text-gray-900"
      style={{ fontFamily: 'Sofia Pro' }}
    >
      <Header />

      <main className="max-w-sm p-6 mx-auto">{children}</main>

      <footer className="flex content-center justify-center pt-12 pb-4 align-middle bg-white text-md">
        <p className="font-bold">
          Powered by{' '}
          <a href="https://twitter.com/aprietof">
            <img src={unicorn} alt="unicorn logo" className="inline-block w-12 pb-2 -ml-1" />
          </a>
        </p>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
